const cookies = {
  0: {
    description:
      'le "cookie" est un enregistrement d\'informations déposé sur votre ordinateur ou votre appareil mobile afin de simplifier, optimiser et rendre plus sûre votre navigation sur le site.',
    id: "issuu",
    label: "issuu",
  },
  1: {
    description:
      'le "cookie" est un enregistrement d\'informations déposé sur votre ordinateur ou votre appareil mobile afin de simplifier, optimiser et rendre plus sûre votre navigation sur le site.',
    id: "calameo",
    label: "calameo",
  },
  2: {
    description:
      'le "cookie" est un enregistrement d\'informations déposé sur votre ordinateur ou votre appareil mobile afin de simplifier, optimiser et rendre plus sûre votre navigation sur le site.',
    id: "youtube",
    label: "youtube",
  },
  3: {
    description:
      'le "cookie" est un enregistrement d\'informations déposé sur votre ordinateur ou votre appareil mobile afin de simplifier, optimiser et rendre plus sûre votre navigation sur le site.',
    id: "dailymotion",
    label: "dailymotion",
  },
  4: {
    description:
      'le "cookie" est un enregistrement d\'informations déposé sur votre ordinateur ou votre appareil mobile afin de simplifier, optimiser et rendre plus sûre votre navigation sur le site.',
    id: "vimeo",
    label: "vimeo",
  },
  5: {
    description:
      'le "cookie" est un enregistrement d\'informations déposé sur votre ordinateur ou votre appareil mobile afin de simplifier, optimiser et rendre plus sûre votre navigation sur le site.',
    id: "g-analytics",
    label: "Google analytics",
  },
};

// CONST

const cookiePopup = document.querySelector("#cnil_banner_consent");
const gaProperty = cookiePopup?.getAttribute("data-site-id");
const disableStr = `ga-disable-${gaProperty}`;
const declineCookies = document.querySelector("#cookieDecline");
const AcceptCookies = document.querySelector("#cookieAccept");
const date = new Date();
date.setTime(date.getTime() + 186 * 24 * 60 * 60 * 1000);
const expires = "; expires=" + date.toGMTString();

// HELPERS

/** @description Fonction utile pour récupérer un cookie à partir de son nom
 *
 * @param NameOfCookie
 * @returns {string|null}
 */
const getCookie = (NameOfCookie) => {
  if (document.cookie.length > 0) {
    let begin = document.cookie.indexOf(`${NameOfCookie}=`);
    if (begin != -1) {
      begin += NameOfCookie.length + 1;
      let end = document.cookie.indexOf(";", begin);
      if (end == -1) end = document.cookie.length;
      return unescape(document.cookie.substring(begin, end));
    }
  }
  return null;
};

// FUNCTIONS

/**
 * @description Creation de la bannière de cookies
 */

const cookiesBanner = () => {
  //cookie content regroupera tout les cookies de l'object cookies
  let cookieContent = "";
  for (let i = 0; i < Object.keys(cookies).length; i++) {
    cookieContent += `<div class="modalCookies__all-choices__input"><input type="checkbox"  data-consent="true" id="id-${cookies[i].id}" data-cookie="${cookies[i].id}" >
                <label  for="id-${cookies[i].id}">${cookies[i].label}</label>
                </div>`;
  }

  const banner = `
            <p class="banner-title">
              <span class="banner-title__icon"></span>
              Cookies
            </p>
            <button type="button" class="btn modalCookies__close" data-dismiss="allKo" data-btn="cookieDecline">
              <span class="sr-only">Fermer</span>
            </button>
            <p>
            Ce site utilise des cookies pour assurer le bon fonctionnement et réaliser des statistiques de visite. En cliquant sur Accepter, vous consentez à l\'utilisation de ces cookies. 
            Vous pouvez à tout moment modifier vos préférences. <br /> Pour plus d\'informations, veuillez consulter la page
            <a href="/Modalites-relatives-aux-cookies">Gestion des cookies </a>.
            </p>
            <div class="modalCookies__btns">
              <button class="btn cookie-btn" data-btn="cookieUpdate" >Paramétrer mes choix</button>
              <button class="btn cookie-btn" data-btn="cookieDecline" data-dismiss="allKo">Tout refuser</button>
              <button class="btn cookie-btn" data-btn="cookieAccept" data-dismiss="allOk">Tout accepter</button>
            </div>
          <div class="modalCookies__all-choices">
          ${cookieContent}
            <div class="modalCookies__all-choices__validate"><button class="btn cookie-btn" data-dismiss="choices" id="cookieValidate">Valider mon/mes choix</button></div>
          </div>`;
  cookiePopup.insertAdjacentHTML("beforeend", banner);

  ListenerBtn();
  document
    .querySelectorAll(".modalCookies__all-choices__input")
    .forEach((input) => {
      input.querySelector("label").addEventListener("click", () => {
        input.classList.toggle("input-open");
      });
    });
  if (cookiePopup) {
    cookiePopup.style.display = "block";
    cookiePopup.style.padding = "0 50px 20px 15px";
  }
};

/**
 * @description Au click sur la croix : Ferme le bandeau de cookies et supprime les cookies
 */

const closePopup = () => {
  document
    .querySelector(".modalCookies__close")
    .addEventListener("click", () => {
      cookiePopup.style.display = "none";
      deleteAll();
    });
};

/**
 * @description Toggle tout les cookies
 */

const toggleAllCookies = () => {
  const allChoices = document.querySelector(".modalCookies__all-choices");
  allChoices.classList.toggle("modalCookies__all-choices--active");
  cookiePopup.classList.toggle("allCookieOpen");
};

/**
 * @description Active  les cookies Analyticcs
 */

const activeAnalytics = () => {
  (function (i, s, o, g, r, a, m) {
    i.GoogleAnalyticsObject = r;
    (i[r] =
      i[r] ||
      function () {
        (i[r].q = i[r].q || []).push(arguments);
      }),
      (i[r].l = 1 * new Date());
    (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m);
  })(
    window,
    document,
    "script",
    "https://www.google-analytics.com/analytics.js",
    "ga"
  );
  ga("create", gaProperty, "auto");
  ga("send", "pageview");
};

/**
 * @description Active tout les cookies
 */

const activeAll = () => {
  const allChoicesInput = document.querySelectorAll(
    ".modalCookies__all-choices__input input"
  );
  var date = new Date();
  date.setTime(date.getTime() + 186 * 24 * 60 * 60 * 1000);
  var expires = "; expires=" + date.toGMTString();
  allChoicesInput.forEach((cookie) => {
    const getCookieName = cookie.getAttribute("data-cookie");
    document.cookie = `${getCookieName}=true; path=/ ${expires}`;
  });
  document.cookie = `hasConsent=true; path=/ ${expires}`;
  activeAnalytics();
};
/**
 *  @description Delete  cookie
 */

const delCookie = (name) => {
  const path = ";path=" + "/";
  let { hostname } = document.location;
  if (hostname.indexOf("www.") === 0) hostname = hostname.substring(4);
  const domain = `${";domain=" + "."}${hostname}`;
  const expiration = "Thu, 01-Jan-1970 00:00:01 GMT";
  document.cookie = `${name}=${path}${domain};expires=${expiration}`;
};

/**
 *  @description Delete analytics cookie
 */

const deleteAnalytics = () => {
  const cookieNames = [
    "__utma",
    "__utmb",
    "__utmc",
    "__utmt",
    "__utmv",
    "__utmz",
    "_ga",
    "_gat",
    "_gid",
  ];

  for (let i = 0; i < cookieNames.length; i++) {
    delCookie(cookieNames[i]);
  }
};
/**
 *  @description Efface tous les types de cookies
 *
 */
const deleteAll = () => {
  const theCookies = document.cookie.split(";");
  const path = ";path=" + "/";
  const expiration = "Thu, 01-Jan-1970 00:00:01 GMT";
  for (let i = 0; i < theCookies.length; i++) {
    if (theCookies[i].substr(0, 1) === " ") {
      var completeCookie = theCookies[i].substr(1);
    } else {
      var completeCookie = theCookies[i];
    }

    const cookie = theCookies[i].split("=");
    let cookieName = completeCookie.split("=")[0];
    if (
      cookieName !== "hasConsent" &&
      !cookieName.startsWith("eZSESSID") &&
      cookieName !== "is_logged_in"
    ) {
      document.cookie = `${cookie[0]}=${path};expires=${expiration}`;
    }
  }
  document.cookie = `hasConsent=false${path}${expires}`;
  deleteAnalytics();
};

/**
 * @description  Consentement des cookies
 */

const closeCnil = (e) => {
  const consent = e.target.getAttribute("data-dismiss");
  let consentValue;
  const allChoicesInput = document.querySelectorAll(
    ".modalCookies__all-choices__input input"
  );
  if (consent === "allOk") {
    activeAll();
    consentValue = "true";
  } else if (consent === "allKo") {
    deleteAll();
    consentValue = "false";
  } else if (consent === "choices") {
    allChoicesInput.forEach((cookie) => {
      if (cookie.checked) {
        cookie.setAttribute("data-consent", "true");
        cookie.checked = true;
      } else {
        cookie.setAttribute("data-consent", "false");
        cookie.checked = false;
      }

      const getCookieName = cookie.getAttribute("data-cookie");
      const getCookieStatus = cookie.getAttribute("data-consent");
      if (getCookieName === "g-analytics") {
        getCookieStatus === "true" ? activeAnalytics() : deleteAnalytics();
      } else {
        if (getCookieStatus === "true") {
          document.cookie = `${getCookieName}=${getCookieStatus}; path = / ${expires}`;
        }
      }
    });
    document.cookie = `hasConsent=choices; path=/ ${expires}`;
  }
  checkCookies();
};

/**
 * @description Gestion des click des différents buttons
 */

const ListenerBtn = () => {
  document.querySelectorAll(".cookie-btn").forEach((btn) => {
    btn.addEventListener("click", (e) => {
      const idBtn = btn.getAttribute("data-btn");
      if (idBtn === "cookieUpdate") {
        toggleAllCookies();
      } else {
        closeCnil(e);
        cookiePopup.style.display = "none";
      }
    });
  });
};

const renderMedias = () => {
  const blocMedias = document.querySelectorAll(".bloc-media");
  blocMedias.forEach((media) => {
    const iframeContent = media
      .querySelector(".bloc-media__target")
      .getAttribute("data-iframe");
    console.log(iframeContent);
    // Les différents types de médias
    const vimeo = iframeContent.includes("vimeo.com/") || null;
    const youtube = iframeContent.includes("youtube") || null;
    const calameo = iframeContent.includes("calameo.com/") || null;
    const dailymotion = iframeContent.includes("dailymotion.com/") || null;
    let mediaActive;
    const disabledIframe = media.querySelector(".iframe_disabled") || null;
    if (vimeo) {
      mediaActive = "vimeo";
    } else if (youtube) {
      mediaActive = "youtube";
    } else if (calameo) {
      mediaActive = "calameo";
    } else if (dailymotion) {
      mediaActive = "dailymotion";
    }
    console.log(iframeContent);
    // On set le nom du cookie sur le bouton  et dans  data-allow-cookies
    const btnEnable = media.querySelector(".btn__enable-media");
    if (btnEnable) {
      btnEnable.setAttribute("data-allow-cookies", mediaActive);
      btnEnable.innerHTML = `Activer cookie ${mediaActive}`;
    }

    if (document.cookie.indexOf(`${mediaActive}=true`) > -1) {
      // si cookie activé
      const getDataIframe = media
        .querySelector(".bloc-media__target")
        .getAttribute("data-iframe");
      if (youtube || calameo) {
        media
          .querySelector(".media-ratio")
          .classList.add("media-ratio--active");
        media
          .querySelector(".bloc-media__target")
          .classList.add("embed-60", "embed-responsive");
      }
      media.querySelector(".bloc-media__target").innerHTML = getDataIframe;
      if (disabledIframe) {
        disabledIframe.remove();
      }
    }
  });

  // check if iframe is allowed in cookies and render HTML content with condition
  const iframeWrapper = document.querySelectorAll(".bloc-iframe"); //  On check toutes les iframes
  iframeWrapper.forEach((iframe) => {
    const blocIframeDatas = document.querySelector(".bloc-iframe__datas");
    const mediaDisabled = iframe.querySelector(".iframe_disabled") || null;
    const iframeId = blocIframeDatas.getAttribute("data-iframe-id");
    const iframeSrc = blocIframeDatas.getAttribute("data-iframe-src");
    const iframeStyle = blocIframeDatas.getAttribute("data-iframe-style");
    if (document.cookie.indexOf("enable-iframe=true") > -1) {
      iframe.innerHTML = `<iframe id="${iframeId}" src="${iframeSrc}" style="${iframeStyle}">
              <p>Votre navigateur ne supporte pas les iframes. Pour consulter la page, <a href="{${iframeSrc}">cliquez ici</a>.</p>
          </iframe>`;
      if (mediaDisabled) {
        mediaDisabled.remove(); // on enlève le bouton d'activation des cookies
      }
    }
  });

  // Bloc HP Video Youtube
  const hpVideo = document.querySelector(".block-youtube");
  if (hpVideo) {
    const btn = hpVideo.querySelector("[data-hp-ytb]");
    const iframeContent = hpVideo
      .querySelector("[data-iframe]")
      .getAttribute("data-iframe");
    const hpVideoIframe = ` <iframe width="703" height="395" src="${iframeContent}" frameborder="0" allowfullscreen></iframe>`;
    if (
      document.cookie.indexOf("hasConsent=true") > -1 ||
      document.cookie.indexOf("youtube") > -1
    ) {
      hpVideo
        .querySelector("[data-iframe]")
        .insertAdjacentHTML("beforeend", hpVideoIframe);
      if (btn) {
        btn.remove();
      }
    }
    if (btn) {
      btn.addEventListener("click", () => {
        var date = new Date();
        date.setTime(date.getTime() + 186 * 24 * 60 * 60 * 1000);
        var expires = "; expires=" + date.toGMTString();
        const getCookieName = "youtube";
        document.cookie = `${getCookieName}=true; path=/ ${expires}`;
        document.cookie = `hasConsent=true; path=/ ${expires}`;
        const hasIframe = hpVideo.querySelector("iframe");
        if (!hasIframe) {
          hpVideo
            .querySelector("[data-iframe]")
            .insertAdjacentHTML("beforeend", hpVideoIframe);
          btn.remove();
        }
      });
    }
  }
};

const renderSocial = () => {
  const popupShare = document.querySelector("#popup-sharethis") || null;
  if (popupShare) {
    const rxsx = popupShare.querySelectorAll(".rxsx-links");
    rxsx.forEach((social) => {
      const toolClass = social.getAttribute("data-cool-class") || null;
      const toolLink = social.getAttribute("data-tool-href") || null;
      const tooltip = social.getAttribute("data-tooltip") || null;
      const srOnly = social.getAttribute("data-sr-only") || null;
      const title = tooltip ? social.getAttribute("title") : "";
      if (document.cookie.indexOf("share-rxsx=true") > -1) {
        popupShare.querySelector(
          "ul"
        ).innerHTML = `	<li><a href=${toolLink}" ${title} class="${toolClass}" role="button" target="_blank"><span class="sr-only">${srOnly}</span></a></li>`;
        if (mediaDisabled) {
          mediaDisabled.remove(); // on enlève le bouton d'activation des cookies
        }
      }
    });
  }
};

const checkCookies = () => {
  const consentCookie = getCookie("hasConsent");
  if (consentCookie) {
    if (document.cookie.indexOf("hasConsent=true") > -1) {
      activeAll();
    } else if (document.cookie.indexOf("hasConsent=false") > -1) {
      window[disableStr] = true;
      deleteAll();
      deleteAnalytics();
    }
  } else {
    if (cookiePopup) {
      cookiePopup.style.display = "block";
    }
    cookiesBanner();
    closePopup();
  }
  renderMedias();
  renderSocial();
};

// Function to allow media cookie and render media in html
const allowMedias = (button) => {
  const media = button.getAttribute("data-allow-cookies");
  document.cookie = `${media}=true; path=/ ${expires}`;
  document.cookie = `hasConsent=choices; path=/ ${expires}`;
  if (media === "share-rxsx") {
    renderSocial();
  } else {
    renderMedias();
  }
};

const allowButtons = document.querySelectorAll(".btn__enable-media") || null;
if (allowButtons) {
  allowButtons.forEach((el) => {
    el.addEventListener("click", () => allowMedias(el));
  });
}

const init = () => {
  checkCookies();
};

init();
